import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import DLSimpleList from "../../../components/DLSimpleList"
import HeaderSub from "../../../components/HeaderSub"

const IndexPage = () => (
  <Layout>
    <HeaderSub mode={`list`} lang={`en`} />
    <SEO title={`TRANS BOOKS DOWNLOADs`} lang={`en`} />
    <DLSimpleList lang={`en`} />
  </Layout>
)

export default IndexPage
